import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Text, Button, ToggleSwitch, Disclaimer } from 'design-system';
import { useQuery } from 'hooks';

import './PricingTables.scss';

const availableIntervals = {
  '1_MONTH': { name: 'Mensuel', unit: 'mois' },
  '1_YEAR': { name: 'Annuel', unit: 'an' },
};

function OfferCard({ offer, recuring, onClick }) {
  function cardBg(offer) {
    const bg = offer.marketing_features.find(a =>
      a.name.split('|')[0].includes('background')
    );
    return bg && bg.name.split('|')[1];
  }

  return (
    <div
      className="offer-card"
      style={{
        background: 'round',
        backgroundImage: `url(https://cdn.mangas.io/images/plans/${cardBg(
          offer
        )}.png)`,
      }}
    >
      <div className="card-bg">
        <div className="avatar-container">
          {offer.marketing_features.map(
            a =>
              a.name.split('|')[0].includes('avatar') && (
                <img
                  src={`${`https://cdn.mangas.io/images/plans/${
                    a.name.split('|')[1]
                  }.png`}`}
                />
              )
          )}
        </div>
        <div className="card-header">
          <img
            src="https://cdn.mangas.io/images/plans/logomio.png"
            width={83}
            height={17}
          />
          <img className="offer-card-logo" src={offer.images[0]} />
          <div className="price-container">
            <Text bold type="display" size="large" className="offer-price">
              {(offer.price.amount / 100).toFixed(2)} €
            </Text>
            <Text>
              {'  '} / {recuring.unit}
            </Text>
          </div>

          <Text className="offer-description" size="small">
            {offer.description}
          </Text>

          {offer.marketing_features.map(
            a =>
              a.name.split('|')[0].includes('button_color') && (
                <Button
                  className="offer-btn"
                  variant={a.name.split('|')[1]}
                  label="S'abonner (7 jours offerts)*"
                  onClick={() => onClick(offer.price._id)}
                />
              )
          )}
        </div>
        {offer.marketing_features.map(f => (
          <div className="offer-feature-list">
            {f.name.split('|')[0].includes('feature') && (
              <div className="offer-feature-item">
                <img
                  width={20}
                  height={20}
                  src={`${`https://cdn.mangas.io/images/plans/icons/${
                    f.name.split('|')[1]
                  }.png`}`}
                />
                <Text size="small">{f.name.split('|')[2]}</Text>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function PricingTables({ onClick }) {
  const [recurringInterval, setRecurringInterval] = useState('1_MONTH');

  const { data, loading } = useQuery(gql`
    query getOffers {
      offers {
        _id
        name
        description
        images
        marketing_features {
          name
        }
        prices {
          _id
          productId
          currency
          amount
          interval
          interval_count
        }
      }
    }
  `);

  if (loading) return null;
  const offers = data.offers;
  const handleToggle = () => {
    recurringInterval === '1_YEAR'
      ? setRecurringInterval('1_MONTH')
      : setRecurringInterval('1_YEAR');
  };
  const selectedRecuring = availableIntervals[recurringInterval];
  const selectedPrices = offers
    .map(o => ({
      ...o,
      price: o.prices.find(
        p => `${p.interval_count}_${p.interval}` === recurringInterval
      ),
    }))
    .sort((offerA, offerB) => offerA.price.amount - offerB.price.amount);
  return (
    <div className="pricing-table">
      <div className="pricing-table-container">
        <Text size="small">Réduction annuelle (2 mois offerts !)</Text>
        <ToggleSwitch onChange={handleToggle} />
      </div>
      <div className="pricing-table-plans">
        {selectedPrices.map(offer => (
          <>
            <OfferCard
              offer={offer}
              recuring={selectedRecuring}
              onClick={onClick}
            />
          </>
        ))}
      </div>
      <Disclaimer
        text="*Profite de 7 jours gratuits sur nos abonnements mensuels et annuels (pour les nouvelles et nouveaux abonné.e.s)."
        className="pricing-disclaimer"
        type="hint"
      />
    </div>
  );
}

export default PricingTables;

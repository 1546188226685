import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks';
import {
  Footer,
  PublishersBloc,
  PricingTablesGift,
  LandingUsersFeedback,
  SEO,
  TitleHeader,
  PricingTables,
  PassCultureCard,
} from 'Components';
import '../Landing/LandingPage.scss';
import './Pricing.scss';

export default function Pricing() {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  return (
    <>
      <SEO
        title="Nos abonnements pour lire tes mangas en ligne | Mangas.io"
        description="Lis tes mangas préférés sans te ruiner grâce à notre système d'abonnement. Pour le prix d'un manga par mois, accède à des milliers de chapitres en illimité"
      />
      <div id="Landing">
        <main id="PricingPage">
          <div className="PricingPageWrapper">
            <div className="pricing">
              <div className="pricing-hero">
                <div className="pricing-hero-image">
                  <img
                    src="https://cdn.mangas.io/images/Hero-image-Yuan-min.png"
                    alt="Logo du Pass Culture"
                    width="906"
                    height="361"
                  />
                </div>

                <h1 className="--title">
                  L’abonnement au prix d’un manga par mois
                </h1>
                <p className="--subtitle --text-secondary">
                  Découvre nos chapitres gratuits au catalogue puis débloque la
                  lecture illimitée grâce à un abonnement au prix d’un manga par
                  mois (disponible aussi sur pass Culture).
                </p>
              </div>
              <div style={{ marginTop: 60 }}>
                <PricingTables
                  onClick={() =>
                    history.push(isAuthenticated ? '/abonnement' : '/signup')
                  }
                />

                <PassCultureCard />
              </div>
            </div>
          </div>
          <section className="pricing-partners">
            <PublishersBloc />
          </section>
          <section className="pricing-voucher">
            <TitleHeader
              title="Nos cartes cadeaux à offrir 🎁"
              subtitle="Un cadeau manga à petit prix !"
            />
            <PricingTablesGift />
          </section>
          <div className="pricingUsersFeedback">
            <LandingUsersFeedback />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink,
} from '@apollo/client';
import { useAuth } from 'hooks';

import packageInfo from '../../package.json';
import { getAnonymousId } from './segment';
import { getGA4ClientId } from './ga4';

async function supportsWebp() {
  if (!window.createImageBitmap) return false;

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const blob = await fetch(webpData).then(r => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false
  );
}

function getHttpLink(token, webpSupport) {
  return new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'same-origin',
    headers: {
      authorization: token && `Bearer ${token}`,
      accept: `application/json${webpSupport ? ',image/webp' : ''}`,
    },
  });
}

function getAnalyticsLink() {
  return new ApolloLink(async (operation, forward) => {
    try {
      const clientId = await getGA4ClientId();
      const identifiers = { 'x-segment-anonymous-id': getAnonymousId() };
      if (clientId) {
        identifiers['x-mangas-io-ga4-clientid'] = clientId;
      }
      operation.setContext(({ headers }) => ({
        headers: {
          ...headers,
          ...identifiers,
        },
      }));
    } catch (e) {
      console.warn(e);
    }
    return forward(operation);
  });
}

function useWebp() {
  const [webpSupport, setSupport] = useState(false);
  useEffect(() => {
    (async () => {
      const res = await supportsWebp();
      setSupport(res);
    })();
  }, []);
  return webpSupport;
}

export default function ApiProvider(props) {
  const { children } = props;
  const { token } = useAuth();
  const webpSupport = useWebp();
  // const wsLink = getWebSocketLink(token);
  const httpLink = getHttpLink(token, webpSupport);
  const analyticsLink = getAnalyticsLink();
  // const link = split(
  //   // split based on operation type
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   // wsLink,
  //   httpLink
  // );
  const cache = new InMemoryCache({
    freezeResults: true,
    // dataIdFromObject: o => o._id,
  });

  const client = new ApolloClient({
    name: 'mangas.io-web',
    version: `${packageInfo.version}-${packageInfo.versionName}`,
    link: from([analyticsLink, httpLink]),
    cache,
    assumeImmutableResults: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

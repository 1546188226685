import React from 'react';
import { useRouteMatch, Link, Switch } from 'react-router-dom';

import {
  Footer,
  SEO,
  LandingUsersFeedback,
  PricingTablesGift,
  LandingFaq,
  CarrouselWrapper,
  Success,
} from 'Components';
import { RedeemPage } from 'Pages';
import { Page, ResponsiveText, OnboardingBackground } from 'UI';
import { PublicRoute } from 'utils';

import './Voucher.scss';
import { Button, Text } from 'design-system';

function VoucherPageSuccess() {
  // TODO : event tracking ?
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Merci beaucoup !</Text>
        <Text>
          Tu recevras rapidement une confirmation par email. Bonne lecture sur
          Mangas.io !
        </Text>
        <Link to="/">
          <Button
            fullWidth
            className="divider"
            variant="primary"
            label="Continuer"
          />
        </Link>
      </Success>
    </OnboardingBackground>
  );
}

function VoucherPage() {
  return (
    <>
      <SEO
        title="Carte cadeau Mangas.io - Offre des centaines de mangas en illimité !"
        description="Fais plaisir à tes ami.e.ss fans de manga : offre 3 mois de mangas à lire en illimité sur Mangas.io et obtiens 3 cartes à collectionner en cadeaux !"
      />
      <Page id="VoucherPageNew">
        <div className="header">
          <img
            className="logo"
            src="https://cdn.mangas.io/images/Hero-image-Yuan.png"
            alt="gift-Yuan"
          />
          <ResponsiveText
            as="h1"
            className="titleVoucher"
            type="display"
            size="medium"
            bold
          >
            Offre des <b className="highlight">centaines de mangas !</b>
          </ResponsiveText>
          <ResponsiveText
            as="h2"
            className="subtitle"
            type="display"
            size="small"
          >
            Voici une <strong>idée cadeau originale à petit prix</strong> pour
            tes ami.e.s fan de mangas : des mangas à lire en illimité sur
            Mangas.io !
          </ResponsiveText>
        </div>
        <div className="process-collector">
          <CarrouselWrapper title="Comment ça marche ?">
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/card.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Tu commandes ta carte cadeau digitale sur Mangas.io.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/plane.png"
                alt=""
                height="60"
              />
              <Text size="small">
                On s’occupe de t'envoyer une confirmation de commande ainsi que
                ton code d’accès par mail.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/gift.png"
                alt=""
                height="60"
              />
              <Text size="small">
                [OPTIONNEL] Imprime le mail contenant le code cadeau et offre-le
                à ton.a proche.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/device.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Une fois ton code activé, tu accèdes à des centaines de mangas
                en illimité !
              </Text>
            </div>
          </CarrouselWrapper>
        </div>
        <PricingTablesGift />
        <div className="header activation">
          <ResponsiveText
            as="h1"
            className="titleVoucher"
            type="display"
            size="medium"
            bold
          >
            J'active <b className="highlight"> ma carte cadeau !</b>
          </ResponsiveText>
          <ResponsiveText
            as="h2"
            className="subtitle"
            type="text"
            size="medium"
          >
            J'ai reçu une carte cadeau <strong>Mangas.io</strong> et je souhaite
            en bénéficier ?
          </ResponsiveText>
          <img
            className="coffeeImg"
            alt="coffee question"
            src="https://cdn.mangas.io/landing/coffee-question-mark.png"
            width={200}
          />
          <Link to="/carte-cadeau/activation">
            <Button
              className="activationBtn"
              size="large"
              variant="primary"
              label="Activation"
            />
          </Link>
        </div>
        <LandingUsersFeedback
          title={
            <Text
              as="h2"
              className="titleVoucher"
              type="display"
              size="large"
              bold
            >
              La <b className="title-feedback">meilleure expérience</b>{' '}
              de manga…
            </Text>
          }
        />

        <LandingFaq title="Questions fréquentes" page_name="faq_carte_cadeau" />
      </Page>
      <Footer />
    </>
  );
}

export default function VoucherPageRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <PublicRoute
        path={`${match.path}/success`}
        component={<VoucherPageSuccess />}
      />
      <PublicRoute
        path={[`${match.path}/activation/:code`, `${match.path}/activation`]}
        component={<RedeemPage />}
      />
      <PublicRoute
        path={[match.path, `${match.path}/cancelled`]}
        component={<VoucherPage />}
      />
    </Switch>
  );
}

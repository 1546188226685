import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import gql from 'graphql-tag';
import { Text, Button } from 'design-system';
import { useMutation, useQuery } from 'hooks';

import './PricingTables.scss';

const GQL_GET_OFFERS = gql`
  query getOffers($type: OfferType) {
    offers(type: $type) {
      _id
      name
      description
      images
      marketing_features {
        name
      }
      prices {
        _id
        productId
        currency
        amount
        interval
        interval_count
      }
    }
  }
`;

const GET_VOUCHER_CHECKOUT = gql`
  mutation checkoutVoucher($productId: ID!) {
    voucherCheckoutSession(productId: $productId) {
      id
      error
    }
  }
`;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeCTA({ productId, ...buttonProps }) {
  const [error, setError] = useState('');
  const [hasLoader, setHasLoader] = useState(false);

  const [checkout] = useMutation(GET_VOUCHER_CHECKOUT, {
    onCompleted: async data => {
      const stripe = await stripePromise;
      const { id, error } = data.voucherCheckoutSession || {};
      if (id) {
        setTimeout(async () => {
          await stripe.redirectToCheckout({
            sessionId: id,
          });
        }, 1500);
      } else if (error) {
        setError(error);
      }
    },
  });
  if (error)
    return (
      <div className="error">
        <p>Une erreur a eu lieu:</p>
        <p>{error}</p>
      </div>
    );
  return (
    <Button
      {...buttonProps}
      loading={hasLoader}
      disabled={hasLoader}
      onClick={() => {
        setHasLoader(true);
        checkout({
          variables: { productId: productId },
        });
      }}
    />
  );
}

function OfferCard({ offer }) {
  function getFromFeatures(featureName) {
    return offer.marketing_features
      .find(a => a.name.startsWith(`${featureName}|`))
      ?.name.split('|')[1];
  }

  return (
    <div
      className="offer-card"
      style={{
        background: 'round',
        backgroundImage: `url(https://cdn.mangas.io/images/plans/${getFromFeatures(
          'background'
        )}.png)`,
      }}
    >
      <div className="card-bg">
        <div className="avatar-container">
          <img
            alt="avatar"
            src={`${`https://cdn.mangas.io/images/plans/${getFromFeatures(
              'avatar'
            )}.png`}`}
          />
        </div>
        <div className="card-header">
          <img
            alt="logo"
            src="https://cdn.mangas.io/images/plans/logomio.png"
            width={83}
            height={17}
          />
          <img alt="offer" className="offer-card-logo" src={offer.images[0]} />

          <Text className="card-description" size="small">
            {offer.description}
          </Text>
          {offer.prices
            .toSorted((a, b) => a.amount - b.amount)
            .map(price => {
              const interval = price.interval === 'YEAR' ? '1 an' : '6 mois';

              return (
                <StripeCTA
                  productId={price._id}
                  className="btnOffer"
                  size="large"
                  fullWidth
                  label={[
                    `Carte cadeau ${interval}`,
                    `${price.amount / 100} €`,
                  ]}
                  variant={getFromFeatures('button_color') || 'secondary'}
                />
              );
            })}
        </div>
        {offer.marketing_features
          .filter(feature => feature.name.startsWith('feature|'))
          .map(feature => {
            const [_, icon, text] = feature.name.split('|');
            return (
              <div className="offer-feature-list">
                <div className="offer-feature-item">
                  <img
                    alt="plan icon"
                    width={20}
                    height={20}
                    src={`${`https://cdn.mangas.io/images/plans/icons/${icon}.png`}`}
                  />
                  <Text size="small">{text}</Text>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function PricingTablesGift() {
  const { data, loading } = useQuery(GQL_GET_OFFERS, {
    variables: {
      type: 'ONE_TIME',
    },
  });

  if (loading) return null;
  const offers = data.offers.toSorted(
    (offerA, offerB) =>
      Math.min(...offerA.prices.map(price => price.amount)) -
      Math.min(...offerB.prices.map(price => price.amount))
  );

  return (
    <div className="pricing-table-plans">
      {offers.map((offer, key) => (
        <OfferCard key={key} offer={offer} />
      ))}
    </div>
  );
}

export default PricingTablesGift;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

import './button.scss';

/**
 * Primary UI component for user interaction
 */
const Button = ({
  variant,
  disabled,
  size,
  label,
  fullWidth,
  icon,
  loading,
  className,
  ...props
}) => {
  const labels = Array.isArray(label) ? label : [label];
  return (
    <button
      type="button"
      className={classnames(
        'mio-button',
        { spaceBetween: labels.length > 1 },
        className,
        {
          primary: variant === 'primary',
          dark: variant === 'dark',
          light: variant === 'light',
          secondary: variant === 'secondary',
          text: variant === 'text',
          gold: variant === 'gold',
          disabled,
          fullWidth,
        },
        { [size]: true },
        { withIcon: !!icon || loading }
      )}
      {...props}
    >
      {loading ? <Icon path={mdiLoading} className="spin" /> : icon}
      {labels.map(label => (
        <span className="label">{label}</span>
      ))}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf([
    'primary',
    'light',
    'dark',
    'secondary',
    'text',
    'gold',
  ]),
  /**
   * How large should the button be?
   */ size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Is this the component disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional icon
   */
  icon: PropTypes.element,
  /**
   * Optional loading state
   */
  loading: PropTypes.bool,
  /**
   * Optional full width
   */
  fullWidth: PropTypes.bool,
  /**
   * Optional className
   */
  className: PropTypes.string,
};

Button.defaultProps = {
  variant: 'secondary',
  disabled: false,
  size: 'medium',
  onClick: undefined,
  icon: null,
  loading: false,
  fullWidth: false,
};

export default Button;
